@import "~bootstrap/scss/bootstrap";

html,
body,
div#root {
    height: 100%;

    div.container-fluid {
        height: 100%;
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
    }
}