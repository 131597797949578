$theme-color-palette-1: #FFF;
$theme-color-palette-2: #0582CA;
$theme-color-palette-3: #003554;
$theme-color-palette-4: #051923;
$theme-color-palette-5: #FFF;

$theme-color-bg: $theme-color-palette-1;
// $theme-color-bg-logo: rgba(255, 255, 255, 0.75);
$theme-color-bg-logo: $theme-color-palette-5;
$theme-color-bg-2: darken($theme-color-bg, 2%);
$theme-color-text: $theme-color-palette-3;
// $theme-color-text-2: lighten($theme-color-text, 20%);
$theme-color-text-2: $theme-color-palette-2;

html,
body,
div#root {
    background-color: $theme-color-bg;
    color: $theme-color-palette-3;
}

.title_section {
    color: $theme-color-palette-3;
}

.title_sub_section {
    color: $theme-color-palette-2;
}

section {
    height: 100%;
    width: 100%;
    // border: 2px red solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav.navbar.fixed-top {

    background-color: $theme-color-palette-4;
    // background-color: rgba(10, 10, 10, 0.9);
    height: 64px;

    @media (max-width: 1366px),
    (max-height: 899px) {
        height: 48px;
    }

    padding: 0;

    .navbar-brand img {
        // background-color: $theme-color-bg-logo;
        height: 36px;

        @media (min-width: 576px) {
            height: 48px;
        }

        @media (min-width: 1200px) {
            height: 64px;
        }

        margin: 0px 0;
        padding: 0 0px;
    }

    .navbar-brand .imageLogo {
        padding: 5px 0;
    }

    &.page-0,
    &.page-3 {
        background: transparent;

        .navbar-brand .imageLogo {
            display: none;
        }
    }

    div.navbar-nav a.nav-link {
        color: darken($theme-color-palette-1, 30%);

        &.active {
            color: $theme-color-palette-1;
        }
    }

    @media (max-width: 575.98px) {
        div.navbar-nav a.nav-link {
            padding: 0 4px !important;
            font-size: 10px !important;
        }

    }
}

#landing .card-body,
#sectors,
#capabilities {
    padding: 0 10%;

    >div {
        width: 100%;
    }
}

#landing {
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 128px 0;

    @media (max-width: 575.98px) {
        padding: 64px 0;
    }

    >div {
        width: 100%;

        >div.card {
            background: none;
            color: $theme-color-palette-1;
            border: none;

            >.card-img-top {
                // background-color: $theme-color-bg-logo;
                padding: 0 20%;
                height: 50%;
                @media (max-width: 575.98px) {
                    padding: 0 10%;
                }
            }

            >.card-body {
                padding: 128px 10% 0 10%;

                @media (min-width: 1366px) {
                    padding: 128px 25% 0 25%;

                }

                @media (max-width: 575.98px) {
                    padding: 64px 10% 0 10%;

                    .icon{
                        font-size: 24px;
                    }
                    .title {
                        font-size: 11px;
                    }
                }
            }
        }
    }

}

#sectors {
    .card {
        margin-top: 1rem;
        border: 0;

        @media (min-width: 1200px) {
            padding: 0px 30px;
        }

        .card-body {
            font-size: 2vw;
            text-align: center;
        }
    }
}

#sectors,
#capabilities {

    .feature_item,
    .alt_feature_item {
        color: $theme-color-palette-3;
    }
}

#contact {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $theme-color-palette-1;
    padding: 0 5%;

    .title_section,
    .title_sub_section {
        color: $theme-color-palette-1;
    }
    .title_section{
        margin: 0px 0px 30px 0;
        @media (min-width: 1200px) {
            margin: 0px 0px 100px 0;
        }
    }

    >div {
        width: 100%;

        >div.row:first-child {
            >div[class^="col-"] {
                @media (max-width: 767.98px) {
                    padding: 5px 0;
                }
            }

            >div[class^="col-"]:first-child {
                padding: 10px 25%;
                text-align: center;

                // background-color: $theme-color-bg-logo;
                @media (min-width: 1200.98px) {
                    padding: 10px 5%;
                }
            }
        }
        div.social_media{
            div[class^="col-"]{
                text-align: center;
                a{
                    color: #FFF;
                    font-size: 2rem;
                    margin: 0 1rem;
                }
            }
        }
    }
}